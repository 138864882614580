/*
ORANGE #e89c30
RED #e15550
*/

:root {
	--highlight: #e89c30;
	--highlight-minor: hsl(2, 60%, 60%);
	
	--success: #349e21;
}

.banner-wip {
	background-color: #ffae00;
	background: repeating-linear-gradient(45deg,
		#ffae00,
		#ffae00 20px,
		#ff8800 20px,
		#ff8800 40px);
	border: 0.3em solid black;
	z-index: 10000;
	font-weight: bold;
	text-align: center;
}

html, body { margin: 0; padding: 0; font-size: 100%; }
body {
	font-family: sans-serif;
	
	display: flex;
	flex-direction: column;
	
	scroll-behavior: smooth;
}

nav {
	background: black;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

nav > span {
	margin: 1em 0;
}

h1 {
	position: relative;
	width: 5em;
	top: -2em;
	margin: 0;
}

h1 > img {
	/* max-height: 5em; */
	left: -2.35em;
	vertical-align: middle;
	position: absolute;
	background: black;
	border-radius: 0 0 45% 45%;
	padding: 1em 1em 0.5em 1em;
}


main {
	display: flex;
	flex-direction: column;
}

section.top {
	height: calc(100vh - 3em);
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 10em auto 1fr auto 1fr 7em;
	grid-template-areas:
		"."
		"title"
		"."
		"scroll"
		"."
		"border"
		;
	place-items: center;
}
section.top > .glass-panel {
	grid-area: title;
}
section.top > .scrollindicator { 
	grid-area: scroll;
}
section.top::after {
	grid-area: border;
}

section {
	position: relative;
	min-height: 100vh;
	width: 100vw;
}
section > .section-bg {
	position: absolute; top: 0; right: 0; left: 0; bottom: 0;
	object-fit: cover;
	z-index: -1000;
	width: 100vw;
	height: 100%;
}

section:not(:first-child)::before, section::after {
	content: " ";
	display: block;
	min-height: 5em;
	width: 100%; height: 100%;
}
section:not(:first-child)::before {
	background:
		/* linear-gradient(to bottom, transparent 60%, #f49d25 80%) top center / 100% 100%, */
		url("../img/big-doodads_A.svg") repeat-x bottom center / contain
		;
	grid-area: top;
	transform: rotate(180deg);
}
section::after {
	background:
		/* linear-gradient(to bottom, transparent 60%, #f49d25 80%) top center / 100% 100%, */
		url("../img/big-doodads_B.svg") repeat-x bottom center / contain
		;
	grid-area: bottom;
}
section:nth-child(even):not(:first-child)::before {
	background:
		/* linear-gradient(to bottom, transparent 60%, #f4ce25 80%) top center / 100% 100%, */
		url("../img/big-doodads_C.svg") repeat-x bottom center / contain;
}

section:nth-child(even)::after {
	background:
		/* linear-gradient(to bottom, transparent 60%, #f4ce25 80%) top center / 100% 100%, */
		url("../img/big-doodads_D.svg") repeat-x bottom center / contain;
}

.grid {
	display: grid;
	grid-template-rows: 7em 1fr auto 2fr 7em;
	gap: 2em 0;
	grid-template-areas: "top top top"
		". . ."
		". primary ."
		". . ."
		"bottom bottom bottom";
}
.grid.left {
	grid-template-columns: 5% auto 50%;
}.grid.right {
	grid-template-columns: 50% auto 5%;
}
.primary { grid-area: primary; }

h2, h3 {
	margin: 0;
	text-align: center;
}


#join {
	display: grid;
	grid-template-columns: 10% 2fr 2fr 10%;
	grid-template-rows: 7em 1fr auto auto 5fr 7em;
	grid-template-areas:
		"top top top top"
		". . . ."
		". header header ."
		". child adult ."
		". . . . "
		"bottom bottom bottom bottom";	
	grid-gap: 1em;
}
#join > header	{
	grid-area: header;
	align-self: center;
	justify-self: center;
	
	padding-left:  5em;
	padding-right: 5em;
}
#join > :where(.adult, .children) {
	padding: 1em 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5em;
}
#join > .adult		{ grid-area: adult; }
#join > .children	{ grid-area: child; }
.price {
	font-size: 2em;
	background: hsla(35, 90%, 55%, 0.7);
	align-self: stretch;
	text-align: center;
	color: white;
}



img, video {
	max-width: 100%;
}

.glass-panel {
	color: #f0f0f0;
	background: #ce651abd;
	/* background: hsla(0 0% 80% / 0.4); */
	backdrop-filter: blur(3px);
	
	padding: 1em;
}
.text-big		{ font-size: 3em; }
.text-med		{ font-size: 1.5em; }
.text-centre	{ text-align: center; }
.icon			{ vertical-align: baseline; }
.spacer		{ width: 0; }
.spacer.sml	{ height: 1em; }
.spacer.med	{ height: 6em; }
.spacer.lrg	{ height: 12em; }

a.boldlink {
	color: white;
	text-decoration: none;
	font-weight: bold;
}
a.boldlink:visited { color: #f9e5e5; }
a.boldlink:hover { color: #dddddd; }
a.boldlink:active { color: #aaaaaa; }

.objpos-20 { object-position: center 20%; }

.objpos-100 { object-position: center 100%; }

/***********************************************************************/

.note, .tip, .important, .warning, .caution {
	padding: 0.4em 0.6em;
	border-radius: 0 0.2em 0.2em 0;
}
.note::before		{ color: #31a0fc; content: "\0024d8\0000a0Note"; }
.tip::before		{ color: #25cb1c; content: "\01f4a1\0000a0Tip"; }
.important::before	{ color: #911ccb; content: "\01f7e3\0000a0Important"; }
.warning::before	{ color: #e0b840; content: "\0026a0\0000a0Warning"; }
.caution::before	{ color: #e04040; content: "\00203c\0000a0Caution"; }

.note::before, .tip::before, .important::before, .warning::before, .caution::before {
        display: block;
        font-weight: bold;
        margin-bottom: 0.3em;
}
.note		{ border-left: 0.2em solid #31a0fc; background: #31a1fc44; }
.tip		{ border-left: 0.2em solid #25cb1c; background: #31fc4944; }
.important	{ border-left: 0.2em solid #911ccb; background: #bc31fc44; }
.warning	{ border-left: 0.2em solid #e0b840; background: #efc74644; }
.caution	{ border-left: 0.2em solid #e04040; background: #ef5f4644; }

/* markdown-it-alert-title. We *should* use their provided styles really.... but meh */
.note > .title,
.tip > .title,
.important > .title,
.warning > .title,
.caution > .title
{ display: none; }



/***********************************************************************/

.action-button {
	display: inline-block;

	padding: 0.35em 0.45em;
	border-radius: 0.25em;

	background: var(--highlight-minor);

	color: white;
	font-size: 1.25em;
	text-decoration: none;

	box-shadow: 0 0 0.5em var(--shadow);

	cursor: pointer;
	transition: box-shadow 0.25s, transform 0.25s;
}

.action-button:hover {
	box-shadow: 0 0.25em 0.65em var(--shadow);
	transform: translateY(-0.25em);
}

.action-button:active {
	box-shadow: 0 0 0.25em var(--shadow);
	transform: translateY(0.1em);
}

.action-button.major {
	background: var(--success);
}
.action-button.smol {
	padding: 0.1em 0.2em;
	font-size: 1.05em;
}


/***********************************************************************/


.steps {
	counter-reset: steps;
	position: relative;
}
.steps::before {
	content: " ";
	position: absolute;
	--size-steps: calc(2.5em + 0.5em * 2);
	--width: 0.25em;
	top: 1em; bottom: 1em; left: calc(var(--size-steps) / 2 - var(--width) / 2);
	width: var(--width);
	background: var(--highlight);
	z-index: -10;
}
.steps > div::before {
	content: counter(steps) ' ';
	display: inline-block;
	--size: 2.5em;
	width: var(--size);
	height: var(--size);
	margin: 0.5em;
	background: var(--highlight);
	color: white;
	font-weight: bold;
	border-radius: 50%;
	line-height: var(--size);
	text-align: center;
}
.steps > div {
	counter-increment: steps;
}